"use client";

import { ShoppingCart } from "@phosphor-icons/react";
import cn from "clsx";
import { useEffect } from "react";
import { useIsClient, useWindowSize } from "usehooks-ts";

import { ownerStore, useOwner, useProfile } from "@up/data";

const FAKE_SELECT_STYLE = cn(
  "relative flex flow-row items-center",
  "pl-3 pr-3 md:pr-5 py-1 max-w-full rounded",
  "text-black bg-white",
  "focus-within:outline focus-within:outline-2 focus-within:outline-offset-1 focus-within:outline-gray-500",
);

export const Go = function ({
  cartCount,
  openCart,
}: {
  cartCount?: number;
  openCart: () => void;
}) {
  const owner = useOwner();
  const profile = useProfile();
  const windowSize = useWindowSize();

  useEffect(() => {
    function go() {
      const nav = document.getElementById("navigation");
      const height = nav?.offsetHeight;
      const selector = document.getElementById("navigation-profile-selector");
      selector?.setAttribute("style", `top: ${height}px`);
    }

    window.addEventListener("resize", go);
    go();

    return window.removeEventListener("resize", go);
  }, [windowSize]);

  function handleSelection(e: React.ChangeEvent<HTMLSelectElement>) {
    const student = profile?.data?.relatedStudents?.find((s) => {
      return s.email === e.target.value;
    });
    if (student) {
      ownerStore.set({
        email: student.email,
        firstName: student.firstName,
        lastName: student.lastName,
      });
    }
  }

  if (
    profile?.data?.type === "Guardian" &&
    profile?.data?.relatedStudents?.length &&
    owner
  ) {
    const name = (() => {
      if (owner.firstName && owner.lastName) {
        return `${owner.firstName} ${owner.lastName}`;
      }
      return "";
    })();
    return (
      <div
        className="overflow-hidden flex justify-center bg-gray-100 border-b border-gray-300 max-w-full py-2 pl-2 sticky z-20"
        data-element="navigation-profile-selector"
        id="navigation-profile-selector"
      >
        <div
          className={`grow min-w-0 flex flex-row items-center justify-center max-w-md sm:grow-0 md:gap-2 ${
            profile.data.relatedStudents.length > 1 ? "grow" : ""
          }`}
        >
          <div className="max-w-full shrink-1 grow overflow-hidden">
            <div
              className={
                profile.data.relatedStudents.length > 1
                  ? FAKE_SELECT_STYLE
                  : "pl-2"
              }
              style={{
                boxShadow:
                  profile.data.relatedStudents.length > 1
                    ? "0px 1px 1px 0px rgba(0, 0, 0, 0.12)"
                    : "none",
              }}
            >
              {profile.data.relatedStudents.length > 1 ? (
                <select
                  id="global-profile-select"
                  name="global-profile-select"
                  className="absolute inset-0 opacity-0 z-10"
                  onChange={handleSelection}
                  value={owner.email}
                >
                  {profile.data.relatedStudents
                    .sort((a, b) => {
                      if (a.firstName < b.firstName) {
                        return -1;
                      }
                      if (a.firstName > b.firstName) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((s) => {
                      return (
                        <option key={s.email} value={s.email}>
                          {s.firstName && s.lastName
                            ? `${s.firstName} ${s.lastName} - `
                            : null}{" "}
                          {s.email}
                        </option>
                      );
                    })}
                </select>
              ) : null}
              <div className="grow overflow-hidden">
                <div className="py-px leading-none text-sm md:text-base font-bold overflow-hidden whitespace-nowrap text-ellipsis">
                  Shopping for {name ?? ""}
                </div>
                {owner.email ? (
                  <div className="text-sm opacity-70 overflow-hidden whitespace-nowrap text-ellipsis">
                    {owner.email}
                  </div>
                ) : null}
              </div>
              {profile.data.relatedStudents.length > 1 ? (
                <div
                  aria-hidden="true"
                  style={{
                    width: 0,
                    height: 0,
                    borderLeft: "6px solid transparent",
                    borderRight: "6px solid transparent",
                    borderTop: "6px solid #555",
                  }}
                />
              ) : null}
            </div>
          </div>

          <div className="shrink-0">
            <button
              className="flex flex-col items-center px-4 underline text-xs"
              onClick={openCart}
              data-element="profile-selector-cart-button"
            >
              <span className="relative">
                {cartCount ? (
                  <span className="animation-slide-and-fade-in absolute top-0 left-[80%] flex items-center justify-center no-underline bg-red-500 text-white rounded-full min-w-[1rem]">
                    {cartCount}
                  </span>
                ) : null}
                <span className="inline-block min-w-9">
                  <ShoppingCart size={24} className="block mx-auto" />
                </span>
              </span>
              Cart
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export const NavigationProfileSelector = function ({
  cartCount,
  openCart,
}: {
  cartCount?: number;
  openCart: () => void;
}) {
  const isClient = useIsClient();
  return isClient ? <Go cartCount={cartCount} openCart={openCart} /> : null;
};
