import type { NextRouter } from "next/router";

export const signinRedirectQueryParam = function (router: NextRouter) {
  if (typeof window === "undefined") return;

  if (window.location.pathname.match(/^\/signin/gm)) {
    return "/";
  }

  if (Object.keys(router.query).length === 0) {
    return window.location.pathname;
  }

  return router.asPath;
};
