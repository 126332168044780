import type { ReactNode } from "react";

type Props = {
  [x: PropertyKey]: any;
  className?: string;
  children: ReactNode;
  col: number;
  hidden: boolean;
};

export default function Grid({
  children,
  className = "",
  col = 3,
  hidden = false,
  ...rest
}: Props): JSX.Element | null {
  if (hidden) {
    return null;
  }

  return (
    <div className={`grid grid-cols-${col} ${className}`} {...rest}>
      {children}
    </div>
  );
}
