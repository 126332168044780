import { useTheme } from "@up/data";

import { Toast, ToastTitle } from "./toast2/toast";

export const BoundryFallback = function () {
  const theme = useTheme();
  return (
    <div className="mx-auto max-w-[600px] pt-8">
      <Toast variant="error">
        <ToastTitle variant="error">Something went wrong</ToastTitle>
        Our team has been notified of this issue. Please try again later. Should
        the problem persist, please{" "}
        <a href={`mailto:${theme.supportEmail}`} className="underline">
          reach out to our support via email
        </a>{" "}
        for further assistance.
      </Toast>
    </div>
  );
};
