import Select from "./Select";
import { usStates } from "../us-states";

import type { RegisterProps } from "../data-types";

type Props = {
  [x: PropertyKey]: any;
  className?: string;
  name: string;
  hasErrors?: boolean;
  placeholderText: string;
  selectedValue?: string;
  registerProps: RegisterProps;
};

export default function SelectState({
  className = "",
  hasErrors = false,
  name,
  placeholderText,
  selectedValue,
  registerProps,
  ...rest
}: Props): JSX.Element {
  return (
    <Select
      className={className}
      data={usStates}
      displayKey="name"
      hasErrors={hasErrors}
      placeholderText={placeholderText}
      selectedValue={selectedValue}
      valueKey="name"
      name={name}
      registerProps={registerProps}
      {...rest}
    />
  );
}
