import {
  applicationIsDenied,
  applicationIsIncomplete,
  applicationIsPendingReview,
} from "@up/data";

import { Toast, ToastTitle } from "./toast2/toast";
import { UPLink } from "./up-link";

import type { TApplicationStatus } from "@up/data";

export const ApplicationWarning = function ApplicationWarning({
  isStudent,
  appStatus,
}: {
  isStudent?: boolean;
  appStatus?: TApplicationStatus;
}) {
  if (applicationIsDenied(appStatus)) {
    return (
      <Toast variant="warning">
        <ToastTitle inline={true}>Application Status:</ToastTitle>
        Not Admitted.
      </Toast>
    );
  }

  if (!isStudent && applicationIsIncomplete(appStatus))
    return (
      <Toast variant="warning">
        <ToastTitle inline={true}>
          Encourage your student to complete their application today.
        </ToastTitle>
        Courses are filling up fast!
      </Toast>
    );

  if (isStudent && applicationIsIncomplete(appStatus)) {
    return (
      <Toast variant="warning">
        <ToastTitle inline={true}>
          <UPLink href="/application" className="underline">
            Complete your application
          </UPLink>
        </ToastTitle>
        Your application is incomplete. Please complete it to continue shopping.
      </Toast>
    );
  }

  if (!isStudent && applicationIsPendingReview(appStatus)) {
    return (
      <Toast variant="warning">
        <ToastTitle inline={true}>
          We've received your student's application.
        </ToastTitle>
        Be on the lookout for an application decision within the next few days.
      </Toast>
    );
  }

  if (isStudent && applicationIsPendingReview(appStatus)) {
    return (
      <Toast variant="warning">
        <ToastTitle inline={true}>We've received your application.</ToastTitle>
        Be on the lookout for an application decision within the next few days.
      </Toast>
    );
  }

  return null;
};
