import { useIsClient } from "usehooks-ts";

import { Navigation as NavigationComponent } from "./_Navigation";
import { AuthNavigation } from "./AuthNavigation";

import type { PrismicNavigationItem } from "./nav-helpers";

export function Navigation(props: {
  cartVariant: string;
  items: Array<PrismicNavigationItem>;
  logo: string;
  logoAlt: string;
  profileVariant: string;
  signInVariant: string;
}) {
  const isClient = useIsClient();

  if (isClient) {
    return <AuthNavigation {...props} />;
  }

  return <NavigationComponent {...props} />;
}
