import type { FormValues } from "./data-types";

// validationValue typed as unknown then cast as number or Record
// where needed. Maybe metaprogramming in TypeScript was a bad idea...
const validate = {
  presence(userEnteredValue: unknown): boolean {
    if (typeof userEnteredValue === "string") {
      return !!userEnteredValue && !!userEnteredValue.trim();
    }

    if (Array.isArray(userEnteredValue)) {
      return !!userEnteredValue.length;
    }

    return !!userEnteredValue;
  },

  maxLength(userEnteredValue: unknown, validationValue: unknown): boolean {
    const vv = validationValue as number;
    if (typeof userEnteredValue !== "string") {
      return false;
    }

    return userEnteredValue.length <= vv;
  },

  minLength(userEnteredValue: unknown, validationValue: unknown): boolean {
    const vv = validationValue as number;
    if (typeof userEnteredValue !== "string") {
      return false;
    }

    return userEnteredValue.length > vv;
  },

  email(userEnteredValue: unknown): boolean {
    if (!userEnteredValue) {
      return true;
    }

    if (typeof userEnteredValue !== "string") {
      return false;
    }
    const re = /^[\w-!#$%&'*+-/=?^_`{|}~]+@([\w-]+\.)+[\w-]{2,4}$/m;
    return re.test(userEnteredValue.toLowerCase());
  },

  uniqueTo(
    userEnteredValue: unknown,
    validationValue: unknown,
    formValues: FormValues,
  ): boolean {
    const vv = validationValue as Record<string, string>;
    const fromValue = formValues[vv["from"]];

    if (typeof userEnteredValue === "string" && typeof fromValue === "string") {
      return userEnteredValue.toLowerCase() !== fromValue.toLowerCase();
    }

    return userEnteredValue !== fromValue;
  },
};

// conditionValue typed as unknown then cast as number or Record
// where needed. Maybe metaprogramming in TypeScript was a bad idea...
const errorMessages = {
  email: function (): string {
    return "Please enter a valid email address";
  },

  presence(): string {
    return "Please complete this required field";
  },

  radio(): string {
    return "Please select an option";
  },

  maxLength: function (conditionValue: unknown): string {
    const max = conditionValue as number;
    return `Please enter a value shorter than ${max} characters`;
  },

  minLength(conditionValue: unknown): string {
    const max = conditionValue as number;
    return `Please enter a value greater than ${max} characters`;
  },

  uniqueTo(conditionValue: unknown): string {
    const config = conditionValue as Record<string, string>;
    return config["errorMessage"];
  },
};

export { errorMessages, validate };
