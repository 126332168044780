import { useStore } from "@nanostores/react";
import { CaretDown, List, ShoppingCart } from "@phosphor-icons/react";
import FocusTrap from "focus-trap-react";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";

import { Cart, isCartOpen } from "@up/cart";
import { applicationIsApproved } from "@up/data";
import { BrowserOnly, UPLink } from "@up/ui";

import { Icon } from "./Icon";
import {
  filterByAuth,
  getButtonItems,
  getTextItems,
  linkType,
} from "./nav-helpers";
import { signinRedirectQueryParam } from "./signin-redirect-query-param";
import styles from "./style.module.scss";

import type { PrismicNavigationItem } from "./nav-helpers";
import type { Auth } from "@kaplan-labs/up-auth-api-client";
import type { ProfileContextType } from "@up/data";
import type {
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  RefObject,
} from "react";

export type ButtonType = "button-link" | "button-primary";

type Props = {
  auth?: Auth;
  cartVariant: string;
  items: Array<PrismicNavigationItem>;
  logo: string;
  logoAlt: string;
  profile?: ProfileContextType;
  profileVariant: string;
  signInVariant: string;
};

// FoucsTrap types don't seem to cover this case so we'll
// give TypeScript a hint
type FocusTrapTypeShim = RefObject<FocusTrap> & {
  focusTrap: {
    updateContainerElements: (el: Element | string) => void;
  };
};

export function Navigation({
  auth,
  cartVariant,
  items,
  logo,
  logoAlt,
  profile,
  profileVariant,
  signInVariant,
}: Props) {
  const $isCartOpen = useStore(isCartOpen);
  const [active, setActive] = useState(false);
  const router = useRouter();

  const toggleMobileNav = function (allowOpen = true) {
    const isOpening = allowOpen && !active;
    !isOpening && reFocus();
    document.body.classList.toggle("navigation-active", isOpening);
    setActive(isOpening);
  };

  const focusTrapOptions = {
    initialFocus:
      "[data-element='navigation-children'] [data-element='navigation-item']:last-child",
    fallbackFocus:
      "[data-element='navigation-children'] [data-element='navigation-item']:last-child",
    delayInitialFocus: true,
    allowOutsideClick: true,
  };

  const focusTrapRef = useRef<FocusTrapTypeShim | null>(null);

  const popoverRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const popover = popoverRef.current;
    popover &&
      popover.style.setProperty("--scrollWidth", popover.scrollWidth + "px");
  }, []);

  const reFocus = function (
    focusTarget: string | Element = "[data-element='navigation-menu']",
  ) {
    if (focusTrapRef.current?.focusTrap) {
      focusTrapRef.current.focusTrap.updateContainerElements(focusTarget);
    }
  };

  const nextHeader = function (
    list: PrismicNavigationItem[],
    start: number,
  ): number {
    for (let i = start + 1; i < list.length; i++) {
      if (list[i].is_header) {
        return i;
      }
    }
    return list.length;
  };

  const courseList = function (items: PrismicNavigationItem[]) {
    return items.map(function (item, i) {
      return (
        <li key={i}>
          <UPLink
            className={`${styles["navigation-item"]} ${item.css_classes || ""}`}
            data-element="navigation-item"
            field={item.link}
          >
            {item.text}
          </UPLink>
        </li>
      );
    });
  };

  const SHOW_CART_BUTTON = (() => {
    if (!profile || !profile.data) return false;
    if (profile.data.type === "Guardian") return false;

    return applicationIsApproved(profile.data.applicationStatus);
  })();

  type NavParentProps = {
    parentItem: PrismicNavigationItem;
    noChildren?: boolean;
  };

  const NavParent = function ({
    parentItem,
    noChildren = false,
    children,
  }: PropsWithChildren<NavParentProps>) {
    return parentItem.link?.link_type !== "Any" ? (
      <UPLink
        className={`${styles["navigation-item"]} ${parentItem.css_classes} ${
          noChildren ? styles["no-children"] : ""
        }`}
        data-element="navigation-item"
        field={parentItem.link}
      >
        {children}
      </UPLink>
    ) : (
      <span
        className={`${styles["navigation-item"]} ${parentItem.css_classes}`}
        data-element="navigation-item"
        tabIndex={0}
      >
        {children}
      </span>
    );
  };

  type NavGroupProps = {
    list: [] | PrismicNavigationItem[];
    parent: PrismicNavigationItem;
    index: number;
  };

  const NavGroup = function ({
    list,
    parent,
    index,
  }: NavGroupProps): ReactNode {
    return (
      <>
        <div className={styles["nav-group-header"]}>
          <button
            type="button"
            className={styles["nav-group-close-btn"]}
            data-element="nav-group-close-btn"
            aria-label="Back to top level"
            onClick={closeNavGroup}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="12"
              fill="none"
              viewBox="0 0 9 12"
              aria-hidden
              className="pointer-events-none"
            >
              <path
                fill="#000"
                d="M8.162 1.41L6.752 0l-6 6 6 6 1.41-1.41L3.582 6l4.58-4.59z"
              ></path>
            </svg>
          </button>
          {parent.link?.link_type !== "Any" && (
            <UPLink
              className={`${styles["landing-page-link"]}`}
              data-element="landing-page-link"
              field={parent.link}
            >
              <span>All {parent.text}</span>
            </UPLink>
          )}
        </div>
        <ul title={parent.text ?? undefined}>
          {courseList(list.slice(index + 1, nextHeader(list, index + 1)))}
        </ul>
      </>
    );
  };

  const openNavGroup = function (
    e: MouseEvent<HTMLButtonElement> & {
      currentTarget: HTMLButtonElement & { parentNode: HTMLLIElement };
    },
  ) {
    const listItem = e.currentTarget.parentNode;
    listItem.setAttribute("aria-expanded", "true");

    //narrow focus on FocusTrap
    if (focusTrapRef.current?.focusTrap && listItem.nextElementSibling) {
      reFocus(listItem.nextElementSibling);
    }
  };

  const closeNavGroup = function () {
    document
      .querySelectorAll("[aria-expanded='true']")
      .forEach((el) => el.setAttribute("aria-expanded", "false"));

    //un-narrow focus of FocusTrap
    if (focusTrapRef.current?.focusTrap) {
      reFocus();
    }
  };

  const subNavClick = function (
    e: MouseEvent<HTMLDivElement> & {
      target: HTMLElement;
    },
  ) {
    if (e.target.nodeName === "A") {
      if (document.activeElement) {
        const el = document.activeElement as HTMLAnchorElement;
        el.blur();
      }
      toggleMobileNav(false);
    }
  };

  return (
    <>
      <div
        className={`${styles["_navigation"]} ${
          active ? styles["navigation-active"] : ""
        } navigation`}
        data-disable-document-scroll={active}
        data-element="navigation"
        id="navigation"
      >
        <nav
          className={styles["navigation-inner"]}
          data-element="navigation-inner"
          data-analytics-component="navigation"
        >
          <a
            href="#main-content"
            className="skip-to-content"
            data-element="skip-to-content"
            onClick={() => toggleMobileNav(false)}
          >
            Skip to content
          </a>

          <div
            className={styles["navigation-left"]}
            data-element="navigation-left"
          >
            <UPLink
              href="/"
              className={styles["navigation-logo"]}
              data-element="navigation-logo"
              onClick={() => toggleMobileNav(false)}
              prefetch={false}
            >
              <img src={logo} alt={logoAlt} />
              <span className="sr-only">Home</span>
            </UPLink>

            <div aria-hidden className={styles["mobile-spacer"]} />

            {SHOW_CART_BUTTON ? (
              <button
                className={styles["navigation-mobile-cart"]}
                onClick={() => {
                  isCartOpen.set(!$isCartOpen);
                }}
              >
                <ShoppingCart size={30} />
                <span className="sr-only">Cart</span>
              </button>
            ) : null}

            <button
              className={styles["navigation-mobile-toggle"]}
              data-element="navigation-mobile-toggle"
              onClick={() => toggleMobileNav()}
              title="Menu"
            >
              <List className={active ? "hidden" : ""} size={36} aria-hidden />
              <span className="sr-only">Menu</span>
            </button>
          </div>
          <FocusTrap
            active={active}
            focusTrapOptions={focusTrapOptions}
            ref={focusTrapRef as unknown as RefObject<FocusTrap>}
          >
            <div
              className={styles["navigation-menu"]}
              data-element="navigation-menu"
              onClick={subNavClick}
              onKeyDown={(e) => e.key === "Escape" && toggleMobileNav(false)}
            >
              <div
                className={styles["navigation-menu-header"]}
                data-element="navigation-menu-header"
              >
                <UPLink
                  href="/"
                  onClick={() => toggleMobileNav(false)}
                  prefetch={false}
                  className={styles["navigation-menu-home"]}
                >
                  <Icon aria-hidden type="home" />
                  <span className="sr-only">Home</span>
                </UPLink>
                <button
                  className={styles["navigation-menu-close"]}
                  onClick={() => toggleMobileNav(false)}
                >
                  <Icon aria-hidden type="close" />
                  <span className="sr-only">Close Menu</span>
                </button>
              </div>
              <div className={styles["center-wrapper"]}>
                <ul
                  className={styles["navigation-center"]}
                  data-element="navigation-center"
                >
                  {getTextItems(items).map((item, index) => {
                    const CHILDREN = item.children || [];

                    return (
                      <li
                        key={index}
                        className={
                          CHILDREN.length ? styles["navigation-parent"] : ""
                        }
                      >
                        <NavParent parentItem={item.properties}>
                          {item.properties.text}
                          {item.properties.link?.link_type !== "Any" && (
                            <Icon type={linkType(item.properties.link)} />
                          )}
                          {CHILDREN.length ? (
                            <CaretDown
                              className={styles["navigation-chevron"]}
                              size={14}
                              weight="fill"
                              role="presentation"
                            />
                          ) : null}
                        </NavParent>

                        {Boolean(CHILDREN.length) && (
                          <ul
                            className={styles["navigation-children"]}
                            data-element="navigation-children"
                            ref={popoverRef}
                          >
                            {CHILDREN[0].is_header
                              ? CHILDREN.map(function (childItem, i) {
                                  return (
                                    childItem.is_header && (
                                      <Fragment key={`child-${i}`}>
                                        <li
                                          role={active ? "button" : undefined}
                                          data-header="true"
                                          aria-expanded={
                                            !active ? undefined : false
                                          }
                                        >
                                          {CHILDREN.length > i + 1 &&
                                          !CHILDREN[i + 1].is_header ? (
                                            <>
                                              <NavParent parentItem={childItem}>
                                                {childItem.text}
                                              </NavParent>
                                              <button
                                                className={`${styles["navigation-item"]} ${childItem.css_classes} `}
                                                data-element="navigation-item"
                                                onClick={openNavGroup}
                                              >
                                                {childItem.text}
                                                <Icon type="parent" />
                                              </button>
                                            </>
                                          ) : (
                                            <NavParent
                                              parentItem={childItem}
                                              noChildren={true}
                                            >
                                              {childItem.text}
                                              <Icon type="internal"></Icon>
                                            </NavParent>
                                          )}
                                        </li>
                                        {CHILDREN.length > i + 1 &&
                                          !CHILDREN[i + 1].is_header && (
                                            <li className={styles["nav-group"]}>
                                              <NavGroup
                                                list={CHILDREN}
                                                parent={childItem}
                                                index={i}
                                              ></NavGroup>
                                            </li>
                                          )}
                                      </Fragment>
                                    )
                                  );
                                })
                              : courseList(CHILDREN)}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                className={styles["navigation-right"]}
                data-element="navigation-right"
              >
                {auth?.currentUser ? null : (
                  <UPLink
                    href={{
                      pathname: "/signin",
                      query: { redirect: signinRedirectQueryParam(router) },
                    }}
                    className="event-navSignin"
                    prefetch={false}
                    data-variant={signInVariant}
                  >
                    Sign In
                  </UPLink>
                )}
                {getButtonItems(items)
                  .filter((item) => {
                    return filterByAuth(auth, item.authenticated_link || false);
                  })
                  .map((item, index) => {
                    return item.link?.link_type !== "Any" ? (
                      <UPLink
                        key={index}
                        className={item.css_classes || ""}
                        data-variant={item.type}
                        field={item.link}
                      >
                        {item.text}
                      </UPLink>
                    ) : (
                      <button
                        key={index}
                        className={item.css_classes || ""}
                        data-variant={item.type}
                      >
                        {item.text}
                      </button>
                    );
                  })}

                {auth?.currentUser ? (
                  <UPLink data-variant={profileVariant} href="/profile">
                    Profile
                  </UPLink>
                ) : null}

                {SHOW_CART_BUTTON ? (
                  <button
                    className={styles["navigation-desktop-cart"]}
                    data-element="navigation-cart-button"
                    data-variant={cartVariant}
                    onClick={() => {
                      isCartOpen.set(!$isCartOpen);
                    }}
                  >
                    <ShoppingCart size={24} className="mr-1 -translate-y-px" />
                    Cart
                  </button>
                ) : null}
              </div>
            </div>
          </FocusTrap>
        </nav>
      </div>
      {
        <BrowserOnly>
          <Cart />
        </BrowserOnly>
      }
    </>
  );
}
