import type { RegisterProps } from "../data-types";

type Props = {
  className?: string;
  disabled?: boolean;
  hasErrors?: boolean;
  value: string;
  registerProps: RegisterProps;
};

export default function InputRadio({
  className = "",
  disabled = false,
  hasErrors = false,
  value,
  registerProps,
  ...rest
}: Props): JSX.Element {
  return (
    <input
      className={`kup-radio ${className}`}
      disabled={disabled}
      id={`${registerProps["name"]}-${value}`}
      type="radio"
      value={value}
      {...registerProps}
      {...rest}
    />
  );
}
