import { create, useModal } from "@ebay/nice-modal-react";
import { useState } from "react";

import { analyticsEvent } from "@up/analytics";
import { useTheme } from "@up/data";
import { Modal, ModalContent } from "@up/ui";

import { markAsDismissed, markAsSuccess } from "../helpers";
import { LeadCaptureTopicsv1Form } from "../LeadCaptureTopicsv1Form";

/**
 * You must define window.leadCaptureConfig.topics before using this component
 */
export const LeadCaptureTopicsv1Modal = create(
  ({ modalID }: { modalID: string }) => {
    const theme = useTheme();
    const modal = useModal();
    const [isSuccess, setIsSuccess] = useState(false);

    const closeModal = () => {
      markAsDismissed(modalID);
      modal.hide();
      setTimeout(() => {
        modal.remove();
      }, 100);
    };

    return (
      <Modal close={closeModal} visible={modal.visible}>
        <ModalContent
          Heading={(headingProps) => {
            return (
              <h2
                {...headingProps}
                className={theme.heading2Class}
                data-testid="lead-capture-heading"
              >
                {isSuccess
                  ? "Access Our Course Syllabi"
                  : `Want more information about the ${theme.partnerOnlineName}?`}
              </h2>
            );
          }}
        >
          <button
            className="absolute right-0 top-0 px-4 pb-1 pt-3 underline lg:pr-9 lg:pt-4"
            style={{ right: 0 }}
            onClick={closeModal}
          >
            close
          </button>

          <LeadCaptureTopicsv1Form
            close={closeModal}
            onSuccess={() => {
              setIsSuccess(true);
              analyticsEvent({
                event: "leadCaptureSuccess",
                formID: modalID,
              });
              markAsDismissed(modalID);
              markAsSuccess(modalID);
            }}
            topics={window.leadCaptureConfig.topics as string[]}
          />
        </ModalContent>
      </Modal>
    );
  },
);
