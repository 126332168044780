import { useStore } from "@nanostores/react";
import { ShoppingCart } from "@phosphor-icons/react";
import FocusTrap from "focus-trap-react";
import { motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";

import { useCart } from "@up/checkout";
import { LoadingModal } from "@up/ui";

import { Portal } from "./Portal";
import { isCartOpen } from "../is-cart-open-store";

import type { ReactNode } from "react";

export const CartWrapper = function ({ children }: { children: ReactNode }) {
  const { isAddingItem, isPolling } = useCart();
  const $isCartOpen = useStore(isCartOpen);
  const isDesktopScreen = useMediaQuery("(min-width: 640px)");
  const closeCart = function () {
    isCartOpen.set(false);
  };

  return (
    <Portal>
      <FocusTrap
        active={$isCartOpen}
        focusTrapOptions={{
          initialFocus: "#cart-heading",
        }}
      >
        <div
          role="dialog"
          aria-labelledby="cart-heading"
          data-disable-document-scroll={$isCartOpen}
          tabIndex={-1}
        >
          {$isCartOpen ? (
            <div
              className="fixed inset-0 bg-black/30 z-40 cursor-pointer animation-fade-in"
              onClick={closeCart}
            />
          ) : null}
          <motion.div
            className={`${
              isDesktopScreen ? "w-96" : "w-full"
            } fixed z-50 top-0 right-0 bottom-0 bg-white overflow-auto overscroll-contain`}
            initial="closed"
            animate={$isCartOpen ? "open" : "closed"}
            variants={{
              open: isDesktopScreen ? { x: "0", y: 0 } : { y: "0", x: 0 },
              closed: isDesktopScreen
                ? { x: "100%", y: 0 }
                : { x: 0, y: "100%" },
            }}
            transition={{
              type: "spring",
              bounce: 0.0,
              duration: 0.4,
            }}
            style={{
              transformOrigin: "top center",
              boxShadow:
                isDesktopScreen && $isCartOpen
                  ? "0px 30px 50px 0px rgba(0, 0, 0, 0.20), 0px 15px 20px 0px rgba(0, 0, 0, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.07)"
                  : "",
            }}
          >
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between p-4 bg-white/95 sticky top-0 z-50 shrink-0">
                <p
                  id="cart-heading"
                  className="flex items-center font-bold uppercase text-xl"
                  tabIndex={0}
                >
                  <ShoppingCart aria-hidden size={32} className="mr-1" /> Cart
                </p>
                <button className="underline" onClick={closeCart}>
                  close
                </button>
              </div>
              <div className="relative grow pb-6">
                {isPolling || isAddingItem ? <LoadingModal local /> : null}
                {children}
              </div>
            </div>
          </motion.div>
        </div>
      </FocusTrap>
    </Portal>
  );
};
