import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { X } from "@phosphor-icons/react";
import { clsx } from "clsx";

import { analyticsEvent } from "@up/analytics";
import { useCart } from "@up/checkout";
import { Ticker } from "@up/ui";
import { Filters } from "./Filters";
import {
  FILTER_NAMES,
  filterCohorts,
  generateDurationFilter,
  generateStartDateFilter,
  getAllInventoryItems,
  handleCohortFilterChange,
  resetCohortFilters,
  useTheme,
} from "@up/data";

import { GRID_CLASSES } from "./grid";

import type { ChangeEvent, ReactNode } from "react";
import type { CatalogueItem, FilterType, InventoryItem } from "@up/data";
import type { CartContextType } from "@up/checkout";

const contentAnimationProps = {
  initial: { opacity: 0, transform: "translateY(10px)", height: 0 },
  animate: {
    opacity: 1,
    transform: "translateY(0)",
    height: "auto",
    transition: {
      duration: 0.2,
      ease: [0.17, 0.55, 0.55, 1],
    },
  },
  exit: { opacity: 0, transform: "translateY(10px)", height: 0 },
};

const itemHasCohorts = function (item: CatalogueItem) {
  return Boolean(item.cohorts.length);
};

const itemNotInCart = function (cart: CartContextType, item: CatalogueItem) {
  if (!cart?.lineItems?.length) {
    return true;
  }
  const isInCart = cart.lineItems
    .map((i) => {
      return i.catalogueItem._id;
    })
    .includes(item._id);
  return !isInCart;
};

const generateFilters = function (
  inventoryItems: InventoryItem[],
  previousFilters?: FilterType[],
) {
  const enabledFilters = {
    startDate: true,
    duration: true,
    course: false,
  };

  const previousF = previousFilters ?? [];

  const startDateFilter = previousF.find(
    (f) => f.name === FILTER_NAMES.START_DATE,
  );

  const durationFilter = previousF.find(
    (f) => f.name === FILTER_NAMES.DURATION,
  );

  return [
    generateStartDateFilter({
      enabledFilters,
      inventoryItems,
      previousFilter: startDateFilter,
    }),
    generateDurationFilter({
      enabledFilters,
      inventoryItems,
      previousFilter: durationFilter,
    }),
  ];
};

const _formatDateForSummary = function (date: string) {
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeZone: "America/Los_Angeles",
  }).format(new Date(date));
};

export const Upsell = function Upsell({
  analytics,
  openDefault = true,
  showToggle = true,
  title = "Interested in another course?",
}: {
  analytics: {
    [key: string]: any;
    location: string;
  };
  openDefault?: boolean;
  showToggle?: boolean;
  title?: ReactNode;
}) {
  const theme = useTheme();
  const cart = useCart();

  const [show, setShow] = useState(openDefault);
  const [selectedCatalogueItem, setSelectedCatalogueItem] = useState<
    CatalogueItem | undefined
  >(undefined);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState<
    InventoryItem | undefined
  >(undefined);
  const [catalogueItems, setCatalogueItems] = useState<CatalogueItem[]>([]);

  const [filters, setFilters] = useState(() => {
    return generateFilters([]);
  });

  useEffect(() => {
    const go = async function () {
      const data = await getAllInventoryItems();
      const _catalogueItems = data
        .filter(itemHasCohorts)
        .filter((item) => itemNotInCart(cart, item));
      setCatalogueItems(
        _catalogueItems.sort((a: CatalogueItem, b: CatalogueItem) =>
          a.name.localeCompare(b.name),
        ),
      );

      const defaultCI = _catalogueItems?.length
        ? _catalogueItems[0]
        : undefined;
      setSelectedCatalogueItem(defaultCI);
      setSelectedInventoryItem(defaultCI?.cohorts[0]);

      if (defaultCI) {
        setFilters(generateFilters(defaultCI.cohorts, filters));
      }
    };

    go();
  }, [cart.lineItems]);

  const filteredCohorts = filterCohorts(
    selectedCatalogueItem?.cohorts ?? [],
    filters,
  );

  useEffect(() => {
    if (!selectedCatalogueItem) {
      return;
    }
    if (!selectedInventoryItem) {
      return;
    }

    /*
      When a filter changes, the currently selected inventory item
      may or may not be in the new list. If it is, we don't want to clear it
    */
    const filteredCohortsContainsSelectedInventoryItem = filteredCohorts.find(
      (c) => {
        return c._id === selectedInventoryItem._id;
      },
    );
    if (!filteredCohortsContainsSelectedInventoryItem) {
      setSelectedInventoryItem(filteredCohorts[0]);
    }
  }, [filters]);

  const handleFilterToggle = function (filterName: string, optionName: string) {
    setFilters(handleCohortFilterChange(filters, filterName, optionName));
  };

  const handleCatalogueItemChange = function (
    e: ChangeEvent<HTMLSelectElement>,
  ) {
    const ci = catalogueItems.find((item) => {
      return item._id === e.target.value;
    });
    if (ci) {
      setSelectedCatalogueItem(ci);
      setFilters(generateFilters(ci.cohorts, filters));
      setSelectedInventoryItem(ci.cohorts[0]);
    }
  };

  const addToCart = async (id: string) => {
    analyticsEvent({
      event: "addToCart",
      ...analytics,
    });
    await cart.addItem(id);
  };

  if (!cart || !catalogueItems?.length) {
    return null;
  }

  return (
    <div className="@container">
      <div
        className={clsx(
          "relative overflow-hidden border border-solid border-yellow-300 mt-8",
          show ? "bg-yellow-50" : "animation-shine bg-yellow-100",
        )}
      >
        <button
          className="w-full grid grid-cols-[1rem_1fr_1rem] pt-4 outline-none @md:grid-cols-[1.5rem_1fr_1.5rem]"
          onClick={() => {
            setShow(!show);
          }}
          style={{ boxShadow: "none" }}
        >
          <div className="col-start-2 col-span-1 flex flex-col mb-4 font-bold justify-between text-left @md:flex-row">
            {title}
            {showToggle ? (
              <span className="gap-1 flex items-center text-sm font-normal opacity-70">
                <X
                  size="16"
                  style={{
                    transform: show ? "rotate(0)" : "rotate(45deg)",
                    transition: "transform 0.2s",
                  }}
                />{" "}
                {show ? "no thanks" : "show me more"}
              </span>
            ) : null}
          </div>
        </button>
        <AnimatePresence>
          {show ? (
            <motion.div {...contentAnimationProps}>
              <div className={`${GRID_CLASSES} mb-4`}>
                <div className="col-start-2 col-span-1">
                  <label htmlFor="add-to-cart-in-cart-catalogue-item">
                    Choose a program
                  </label>
                  <select
                    className="w-full mb-2 bg-white border border-gray-400 rounded py-1 px-3 shadow-sm"
                    id="add-to-cart-in-cart-catalogue-item"
                    name="program"
                    value={selectedCatalogueItem?._id ?? "0"}
                    onChange={handleCatalogueItemChange}
                  >
                    {catalogueItems?.map((p) => {
                      return (
                        <option key={p._id} value={p._id}>
                          {p.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <Filters
                filters={filters}
                handleFilterToggle={handleFilterToggle}
              />

              {selectedCatalogueItem ? (
                <div className={GRID_CLASSES}>
                  <div className="col-start-2 col-span-1">
                    {filteredCohorts.length ? (
                      <>
                        <label htmlFor="add-to-cart-in-cart-cohort">
                          <Ticker
                            value={`Choose a date (${filteredCohorts.length.toString()} available)`}
                          />
                        </label>
                        <select
                          className="w-full bg-white border border-gray-400 rounded py-1 px-3 shadow-sm"
                          id="add-to-cart-in-cart-cohort"
                          name="cohort"
                          value={selectedInventoryItem?._id ?? "0"}
                          onChange={(e) => {
                            setSelectedInventoryItem(
                              selectedCatalogueItem.cohorts.find((c) => {
                                return c._id === e.target.value;
                              }),
                            );
                          }}
                        >
                          <option key="_" value="0" />

                          {filteredCohorts.map((c) => {
                            return (
                              <option key={c._id} value={c._id}>
                                {c.name}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    ) : (
                      <div className="animation-fade-in">
                        <div className="pt-2 mb-2">
                          No courses match these filters
                        </div>
                        <button
                          className={`${theme.buttonPrimaryClass} w-full`}
                          onClick={() => {
                            setFilters(resetCohortFilters(filters));
                          }}
                        >
                          Reset Filters
                        </button>
                      </div>
                    )}
                    <div aria-hidden className="h-4" />
                  </div>
                </div>
              ) : null}
              <div
                className={`${GRID_CLASSES} border-t border-dashed border-yellow-300`}
              >
                <div className="col-start-2 col-span-1">
                  <div aria-hidden className="h-4" />
                  <AnimatePresence>
                    {selectedInventoryItem && filteredCohorts.length ? (
                      <motion.div
                        className="leading-tight"
                        {...contentAnimationProps}
                      >
                        <div className="opacity-70">
                          {_formatDateForSummary(
                            selectedInventoryItem.startDate,
                          )}{" "}
                          —{" "}
                          {_formatDateForSummary(selectedInventoryItem.endDate)}{" "}
                          ({selectedInventoryItem.cohortLength})
                        </div>
                        <div className="font-bold @lg:text-lg">
                          {selectedInventoryItem.catalogueItem.name}
                        </div>
                        <div aria-hidden className="h-2" />
                      </motion.div>
                    ) : null}
                  </AnimatePresence>

                  {selectedInventoryItem && filteredCohorts.length ? (
                    <div
                      className={clsx(
                        "flex items-center justify-between gap-4 pt-3",
                        "@md:justify-start",
                      )}
                    >
                      <button
                        className={theme.buttonPrimaryClass}
                        data-location={analytics.location}
                        data-testid="add-to-cart-button"
                        disabled={cart.isAddingItem}
                        onClick={() => {
                          addToCart(selectedInventoryItem._id);
                        }}
                      >
                        Add to Cart
                      </button>
                      <span className="animation-fade-in">
                        <Ticker
                          value={selectedInventoryItem.formattedRetailPrice}
                        />
                      </span>
                    </div>
                  ) : (
                    <button
                      disabled
                      className={`${theme.buttonPrimaryClass} opacity-50 cursor-not-allowed`}
                    >
                      Add to Cart
                    </button>
                  )}
                  <div aria-hidden className="h-4" />
                </div>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
};
