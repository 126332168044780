export function WaveCheck({ size, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 256 256"
      {...rest}
    >
      <path fill="none" d="M0 0H256V256H0z"></path>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        d="M54.5 201.5c-9.2-9.2-3.1-28.5-7.8-39.8S24 140.5 24 128s17.8-22 22.7-33.7-1.4-30.6 7.8-39.8 28.5-3.1 39.8-7.8S115.5 24 128 24s22 17.8 33.7 22.7 30.6-1.4 39.8 7.8 3.1 28.5 7.8 39.8S232 115.5 232 128s-17.8 22-22.7 33.7 1.4 30.6-7.8 39.8-28.5 3.1-39.8 7.8S140.5 232 128 232s-22-17.8-33.7-22.7-30.6 1.4-39.8-7.8z"
        className="wave-circle"
      ></path>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        d="M172 104L113.3 160 84 132"
      ></path>
    </svg>
  );
}
