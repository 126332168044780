import type { RegisterProps } from "../data-types";

const optionsFromArray = function (data: Array<any>) {
  return data.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));
};

export type ArrayProps = {
  [x: PropertyKey]: any;
  className?: string;
  data: any;
  displayArrow?: boolean;
  hasErrors?: boolean;
  name: string;
  placeholderText?: string;
  selectedValue?: string;
  registerProps: RegisterProps;
};

export default function SelectFromArray({
  className = "",
  data,
  displayArrow = true,
  hasErrors = false,
  name,
  placeholderText,
  selectedValue,
  registerProps,
  ...rest
}: ArrayProps): JSX.Element {
  return (
    <select
      aria-invalid={hasErrors}
      className={`kup-select ${hasErrors ? "kup-select-has-errors" : ""} ${
        displayArrow ? "kup-select-arrow" : ""
      } ${className}`}
      defaultValue={selectedValue}
      id={name}
      {...registerProps}
      {...rest}
    >
      {placeholderText && <option value="">{placeholderText}</option>}
      {optionsFromArray(data)}
    </select>
  );
}
