const usStates = [
  {
    id: 1,
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    id: 2,
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    id: 3,
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    id: 4,
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    id: 5,
    name: "California",
    abbreviation: "CA",
  },
  {
    id: 6,
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    id: 7,
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    id: 8,
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    id: 9,
    name: "District of Columbia",
    abbreviation: "DC",
  },
  {
    id: 10,
    name: "Florida",
    abbreviation: "FL",
  },
  {
    id: 11,
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    id: 12,
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    id: 13,
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    id: 14,
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    id: 15,
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    id: 16,
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    id: 17,
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    id: 18,
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    id: 19,
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    id: 20,
    name: "Maine",
    abbreviation: "ME",
  },
  {
    id: 21,
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    id: 22,
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    id: 23,
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    id: 24,
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    id: 25,
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    id: 26,
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    id: 27,
    name: "Montana",
    abbreviation: "MT",
  },
  {
    id: 28,
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    id: 29,
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    id: 30,
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    id: 31,
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    id: 32,
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    id: 33,
    name: "New York",
    abbreviation: "NY",
  },
  {
    id: 34,
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    id: 35,
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    id: 36,
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    id: 37,
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    id: 38,
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    id: 39,
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    id: 40,
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    id: 41,
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    id: 42,
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    id: 43,
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    id: 44,
    name: "Texas",
    abbreviation: "TX",
  },
  {
    id: 45,
    name: "Utah",
    abbreviation: "UT",
  },
  {
    id: 46,
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    id: 47,
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    id: 48,
    name: "Washington",
    abbreviation: "WA",
  },
  {
    id: 49,
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    id: 50,
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    id: 51,
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export { usStates };
