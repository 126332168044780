import { useAuthStore } from "@kaplan-labs/up-auth-api-client";

import { useProfile } from "@up/data";

import { Navigation } from "./_Navigation";

import type { PrismicNavigationItem } from "./nav-helpers";

type Props = {
  items: Array<PrismicNavigationItem>;
  logo: string;
  logoAlt: string;
  cartVariant: string;
  profileVariant: string;
  signInVariant: string;
};

export function AuthNavigation({
  items,
  logo,
  logoAlt,
  cartVariant,
  profileVariant,
  signInVariant,
}: Props) {
  const auth = useAuthStore();
  const profile = useProfile();

  return (
    <Navigation
      auth={auth}
      cartVariant={cartVariant}
      items={items}
      logo={logo}
      logoAlt={logoAlt}
      profile={profile}
      profileVariant={profileVariant}
      signInVariant={signInVariant}
    />
  );
}
