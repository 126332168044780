import type { RegisterProps } from "../data-types";

type Props = {
  [x: PropertyKey]: any;
  className?: string;
  hasErrors?: boolean;
  index?: number | string;
  value: string;
  registerProps: RegisterProps;
};

export default function InputCheckbox({
  className = "",
  hasErrors = false,
  index = 1,
  value,
  registerProps,
  ...rest
}: Props): JSX.Element {
  return (
    <input
      aria-invalid={hasErrors}
      className={`kup-checkbox ${className}`}
      id={`${registerProps["name"]}-${index}`}
      type="checkbox"
      value={value}
      {...registerProps}
      {...rest}
    />
  );
}
