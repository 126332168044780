import { useOwner } from "./owner-context";
import { useProfile } from "./profile-context";

export const useOwnerProfile = function () {
  const owner = useOwner();
  const profile = useProfile();

  if (!profile?.data) {
    return undefined;
  }

  if (profile.data.type === "Student") {
    return profile.data;
  }

  if (!owner) {
    return undefined;
  }

  if (profile?.data?.type === "Guardian") {
    const student = profile.data.relatedStudents?.find((related) => {
      return related.email === owner.email;
    });

    if (student) {
      return student;
    }

    // if owner if Guardian
    if (profile.data.email === owner.email) {
      return profile.data;
    }
  }

  return undefined;
};
