/* eslint no-case-declarations: 0 */

import ElementWrapper from "./ElementWrapper";
import ErrorMessage from "./ErrorMessage";
import Grid from "./Grid";
import HelpText from "./HelpText";
import {
  createRegistration,
  createValidation,
  dependentElementConditionValueIsMet,
  getErrorMessage,
} from "../form-element-helpers";
import AutoTextarea from "../form-elements/AutoTextarea";
import InputCheckbox from "../form-elements/InputCheckbox";
import InputRadio from "../form-elements/InputRadio";
import InputText from "../form-elements/InputText";
import Label from "../form-elements/Label";
import LabelCheckbox from "../form-elements/LabelCheckbox";
import LabelRadio from "../form-elements/LabelRadio";
import Select from "../form-elements/Select";
import SelectCountry from "../form-elements/SelectCountry";
import SelectState from "../form-elements/SelectState";
import { errorMessages } from "../validation";

import type {
  Checkbox,
  CountrySelect,
  Element,
  Fileupload,
  FormValues,
  LayoutFieldset,
  LayoutGrid,
  ProgramSelection,
  Radio,
  Richtext,
  SelectField,
  StateSelect,
  Textarea,
  Textfield,
} from "../data-types";
import type { Control, FieldErrors, UseFormRegister } from "react-hook-form";

type Props = {
  control: Control;
  errors: FieldErrors;
  element: Element;
  formValues: FormValues;
  resetField?: (field: string) => void;
  register: UseFormRegister<any>;
  wrapperProps?: Record<string, unknown>;
};

export default function RenderElement({
  control,
  errors,
  element,
  formValues,
  resetField,
  register,
  wrapperProps = {},
}: Props): JSX.Element | null {
  const name = element.name as string;
  // I heart Typescript
  const _e = errors[name];
  const _type = _e ? (_e.type as unknown as string) : undefined;
  const error =
    _e && _type && element.validate
      ? getErrorMessage(_type, element.validate)
      : "";

  const isVisible =
    "dependent" in element
      ? dependentElementConditionValueIsMet(element.dependent, formValues)
      : true;

  if (!isVisible) {
    return null;
  }

  switch (element.type) {
    case "checkbox":
      const checkbox = element as Checkbox;
      return (
        <ElementWrapper
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <HelpText>{checkbox.helpText}</HelpText>
          {checkbox.options.map((option, index) => {
            return (
              <LabelCheckbox key={index} html={option.html} text={option.text}>
                <InputCheckbox
                  className={
                    checkbox.meta?.type
                      ? `kup-checkbox-${checkbox.meta.type}`
                      : ""
                  }
                  disabled={!isVisible}
                  index={index}
                  registerProps={createRegistration(
                    register,
                    element,
                    formValues,
                  )}
                  value={option.value}
                />
              </LabelCheckbox>
            );
          })}
          <ErrorMessage text={error} />
        </ElementWrapper>
      );

    case "countryselect":
      const countrySel = element as CountrySelect;
      return (
        <ElementWrapper
          aria-hidden={!isVisible}
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <Label
            htmlFor={countrySel.name}
            required={Boolean(countrySel.validate)}
            text={countrySel.label}
          />
          <SelectCountry
            disabled={!isVisible}
            hasErrors={Boolean(error)}
            name={countrySel.name}
            placeholderText="Please Select"
            registerProps={createRegistration(register, element, formValues)}
          />
          <ErrorMessage text={error ? errorMessages.radio() : null} />
        </ElementWrapper>
      );

    case "fieldset":
      const fieldset = element as LayoutFieldset;
      return (
        <fieldset
          aria-hidden={!isVisible}
          className={`kup-fieldset ${
            fieldset.meta?.type ? `kup-fieldset-${fieldset.meta.type}` : ""
          }`}
          hidden={!isVisible}
        >
          <legend className="hidden">{fieldset.legend}</legend>
          {fieldset.elements.map((element, index) => {
            return (
              <RenderElement
                key={index}
                control={control}
                element={element}
                errors={errors}
                formValues={formValues}
                register={register}
              />
            );
          })}
        </fieldset>
      );

    case "fileupload":
      const fileUpload = element as Fileupload;
      return (
        <ElementWrapper
          aria-hidden={!isVisible}
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <Label htmlFor={fileUpload.name} text={fileUpload.label} />
          <HelpText>{fileUpload.helpText}</HelpText>
          <input
            className={`kup-fileupload ${
              fileUpload.meta?.type
                ? `kup-fileupload-${fileUpload.meta.type}`
                : ""
            }`}
            disabled={!isVisible}
            type="file"
            id={fileUpload.name}
            {...createRegistration(register, element, formValues)}
          />
        </ElementWrapper>
      );

    case "layout-grid":
      const layout = element as LayoutGrid;
      return (
        <Grid
          className={
            layout.meta?.type ? `kup-layout-grid-${layout.meta.type}` : ""
          }
          col={layout.columns}
          hidden={!isVisible}
        >
          {layout.elements.map((element, index) => {
            return (
              <RenderElement
                key={index}
                control={control}
                element={element}
                register={register}
                errors={errors}
                formValues={formValues}
              />
            );
          })}
        </Grid>
      );

    case "program-selection":
      const program = element as ProgramSelection;
      const filtered = program.options.filter(
        function removeProgramsWithoutCohorts(option) {
          return option.elements[0].options.length;
        },
      );

      return (
        <ElementWrapper
          className="kup-program-selection"
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <HelpText>{program.helpText}</HelpText>
          <ErrorMessage text={error} />
          {filtered.map((option, index) => {
            const programIsSelected =
              formValues &&
              formValues[program.name] &&
              formValues[program.name]!.includes(option.value);

            const regProps = createRegistration(register, element, formValues);
            return (
              <div
                className={`kup-program relative py-2 px-1 ${
                  programIsSelected ? "kup-program-selected py-3" : ""
                }`}
                key={option.value}
              >
                <LabelCheckbox text={option.text} html={option.html}>
                  <InputCheckbox
                    disabled={!isVisible}
                    index={index}
                    value={option.value}
                    registerProps={regProps}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        resetField?.("program-selection-undecided");
                      }
                      regProps.onChange(event);
                    }}
                  />
                </LabelCheckbox>
                {option.elements.map((cohort, index) => {
                  return (
                    <RenderElement
                      key={index}
                      control={control}
                      element={cohort}
                      errors={errors}
                      formValues={formValues}
                      register={register}
                    />
                  );
                })}
              </div>
            );
          })}
        </ElementWrapper>
      );

    case "radio":
      const radio = element as Radio;
      return (
        <ElementWrapper
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <Label required={Boolean(radio.validate)} text={radio.label} />
          <HelpText>{radio.helpText}</HelpText>
          <div
            className={
              element.meta?.columns
                ? `grid grid-col-${element.meta.columns}`
                : ""
            }
          >
            {radio.options.map((option, index) => {
              const val = formValues[radio.name];
              const checked = val === option.value;
              return (
                <LabelRadio
                  className={checked ? "kup-radio-label-checked" : ""}
                  key={index}
                  text={option.text}
                >
                  <InputRadio
                    className={`${
                      radio.meta?.type ? `kup-radio-${radio.meta.type}` : ""
                    } ${checked ? "kup-radio-checked" : ""}`}
                    disabled={!isVisible}
                    registerProps={createRegistration(
                      register,
                      element,
                      formValues,
                    )}
                    value={option.value}
                  />
                </LabelRadio>
              );
            })}
          </div>
          <ErrorMessage text={error ? errorMessages.radio() : ""} />
        </ElementWrapper>
      );

    case "richtext":
      const richtext = element as Richtext;
      return (
        <div
          aria-hidden={!isVisible}
          className={`kup-rich-text ${
            richtext.meta?.type ? `kup-rich-text-${richtext.meta.type}` : ""
          }`}
          dangerouslySetInnerHTML={{ __html: richtext.html || "" }}
          hidden={!isVisible}
        />
      );

    case "select":
      const sel = element as SelectField;
      return (
        <ElementWrapper
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <Label
            htmlFor={sel.name}
            required={Boolean(sel.validate)}
            text={sel.label}
          />
          <Select
            className={sel.meta?.type ? `kup-select-${sel.meta.type}` : ""}
            data={sel.options}
            disabled={!isVisible}
            displayKey={sel.displayKey}
            valueKey={sel.valueKey}
            hasErrors={Boolean(error)}
            placeholderText="Please select"
            name={sel.name}
            registerProps={createRegistration(register, element, formValues)}
          />
          <ErrorMessage text={error ? errorMessages.radio() : null} />
        </ElementWrapper>
      );

    case "stateselect":
      const stateSel = element as StateSelect;
      return (
        <ElementWrapper
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <Label
            htmlFor={stateSel.name}
            required={Boolean(stateSel.validate)}
            text={stateSel.label}
          />
          <HelpText>{stateSel.helpText}</HelpText>
          <SelectState
            disabled={!isVisible}
            name={stateSel.name}
            hasErrors={Boolean(error)}
            placeholderText="Please Select"
            registerProps={createRegistration(register, element, formValues)}
          />
          <ErrorMessage text={error ? errorMessages.radio() : null} />
        </ElementWrapper>
      );

    case "textarea":
      const textarea = element as Textarea;
      return (
        <ElementWrapper
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <Label
            htmlFor={textarea.name}
            required={Boolean(textarea.validate)}
            text={textarea.label}
          />
          <HelpText>{textarea.helpText}</HelpText>
          <AutoTextarea
            className={`kup-textarea ${
              textarea.meta?.type ? `kup-textarea-${textarea.meta.type}` : ""
            } ${error ? "kup-textarea-has-errors" : ""}`}
            control={control}
            disabled={!isVisible}
            name={textarea.name}
            validation={
              textarea.validate
                ? createValidation(textarea.validate, formValues)
                : undefined
            }
          />
          <ErrorMessage text={error} />
        </ElementWrapper>
      );

    case "textfield":
    case "emailfield":
      const textfield = element as Textfield;
      return (
        <ElementWrapper
          hasErrors={Boolean(error)}
          hidden={!isVisible}
          {...wrapperProps}
        >
          <Label
            htmlFor={textfield.name}
            required={Boolean(textfield.validate)}
            text={textfield.label}
          />
          <HelpText>{textfield.helpText}</HelpText>
          <InputText
            className={
              textfield.meta?.type
                ? `kup-input-text-${textfield.meta.type}`
                : ""
            }
            disabled={!isVisible}
            hasErrors={Boolean(error)}
            type={element.type === "emailfield" ? "email" : "text"}
            registerProps={createRegistration(register, element, formValues)}
          />
          <ErrorMessage text={error} />
        </ElementWrapper>
      );

    default:
      return <div>Unknown type: {element.type}</div>;
  }
}
