/* eslint-disable @next/next/no-img-element */
import { UPLink } from "@up/ui";

import { LIBRARY_THEME } from "~/lib/theme";

export default function Footer() {
  return (
    <footer
      id="footer"
      className="theme-blue-dark text-center text-base pt-12 pb-6 relative z-40 lg:py-6 lg:px-12 lg:flex lg:items-center"
    >
      <img
        className="mb-16 mx-auto lg:mb-0"
        src="/logo-footer.png"
        alt="Georgetown University"
        width="147"
        height="31"
      />

      <div className="px-3 grow">
        <UPLink
          className="underline p-1 inline-block m-px"
          href="/sitemap"
          prefetch={false}
        >
          Sitemap
        </UPLink>{" "}
        &nbsp;
        <UPLink
          className="underline p-1 inline-block m-px"
          href="/privacy-policy"
          prefetch={false}
        >
          Privacy Policy
        </UPLink>{" "}
        &nbsp;
        <UPLink
          className="underline p-1 inline-block m-px"
          href="/terms-of-use"
          prefetch={false}
        >
          Terms of Use
        </UPLink>{" "}
        &nbsp;
        <a
          className="underline p-1 inline-block m-px"
          href={`mailto:${LIBRARY_THEME.supportEmail}`}
        >
          Contact Info
        </a>{" "}
        &nbsp;
        <a
          className="underline m-px p-1 block sm:inline-block"
          href="https://docs.google.com/forms/d/e/1FAIpQLSd2cgxOVvjV8PRxnqc3NvuG7AMp2RNb4WZAP_0AQ_BBLn6W-A/viewform"
          rel="noreferrer"
          target="_blank"
        >
          Do Not Sell or Share My Data
        </a>
        <div className="font-serif pt-6 lg:pt-3">
          &copy; {new Date().getFullYear()} Georgetown University
        </div>
      </div>
    </footer>
  );
}
