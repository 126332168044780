"use client";

import { useEffect, useRef } from "react";

export const useAutoFocus = () => {
  const elRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (elRef.current) {
      elRef.current.focus();
    }
  }, []);

  return elRef;
};
