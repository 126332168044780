export const PreviewBanner = function PreviewBanner() {
  return (
    <div
      className="text-center"
      style={{ color: "white", padding: "3px", backgroundColor: "red" }}
    >
      {" "}
      This is a preview.{" "}
      <a href="/api/exit-preview" className="underline">
        exit preview mode
      </a>
      .
    </div>
  );
};
