import type { ReactNode } from "react";

type Props = {
  children?: ReactNode | undefined | null;
  className?: string;
};

export default function HelpText({
  children,
  className = "",
  ...rest
}: Props): JSX.Element | null {
  if (children) {
    return (
      <div className={`kup-help-text ${className}`} {...rest}>
        {children}
      </div>
    );
  } else {
    return null;
  }
}
