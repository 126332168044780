"use client";

import { motion } from "framer-motion";

import { Spinner } from "../spinner/spinner";

export function LoadingModal({
  local = false,
  text = "Loading",
}: {
  local?: boolean;
  text?: string;
}) {
  return (
    <div
      className={`animation-fade-in ${
        local ? "absolute" : "fixed"
      } inset-0 z-30 bg-black/10`}
    >
      <motion.div
        className="pointer-events-none fixed left-1/2 top-1/2 flex items-center whitespace-nowrap rounded-2xl border border-solid border-gray-200 bg-white py-3 pl-4 pr-5 font-medium shadow-xl"
        data-testid="checkout-global-loader"
        initial={{ scale: 0.8, opacity: 0, x: "-50%", y: "-50%" }}
        animate={{ scale: 1.0, opacity: 1, x: "-50%", y: "-50%" }}
        exit={{ scale: 0.8, opacity: 0, x: "-50%", y: "-50%" }}
        transition={{ type: "spring", duration: 0.5, bounce: 0.5 }}
      >
        <Spinner className="mr-3" /> {text}
      </motion.div>
    </div>
  );
}
