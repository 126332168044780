import RequiredFlag from "./RequiredFlag";

import type { ReactNode } from "react";

type Props = {
  [x: PropertyKey]: any;
  children?: ReactNode;
  className?: string;
  html?: string;
  required?: boolean;
  text?: string;
};

export default function LabelBase({
  children,
  className = "",
  html,
  required = false,
  text,
  ...rest
}: Props): JSX.Element {
  return (
    <label className={`kup-label mb-1 ${className}`} {...rest}>
      {children && (
        <span className="kup-label-text">
          {children} {required && <RequiredFlag />}
        </span>
      )}

      {!children && (
        <span className="kup-label-text">
          {html && <span dangerouslySetInnerHTML={{ __html: html }} />}
          {!html && text}
          {required && <RequiredFlag />}
        </span>
      )}
    </label>
  );
}
