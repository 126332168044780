import RequiredFlag from "./RequiredFlag";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  html?: string;
  Label?: ReactNode;
  required?: boolean;
  text?: string;
};

export default function LabelCheckbox({
  children,
  className = "",
  html,
  Label,
  required = false,
  text,
}: Props): JSX.Element {
  return (
    <label
      className={`custom-field custom-field-checkbox kup-checkbox-label ${className}`}
    >
      {children}
      {Label && (
        <div className="custom-field-label">
          <span className="kup-checkbox-label-text">
            {Label}
            {required && <RequiredFlag />}
          </span>
          <span className="custom-field-el" />
        </div>
      )}
      {!Label && (
        <div className="custom-field-label">
          <span className="kup-checkbox-label-text">
            {html && <span dangerouslySetInnerHTML={{ __html: html }} />}
            {!html && text}
            {required && <RequiredFlag />}
          </span>
          <span className="custom-field-el" />
        </div>
      )}
    </label>
  );
}
