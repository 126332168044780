import { useState } from "react";

import { ChangeDate } from "./ChangeDate";
import { LineItemConfirmDelete } from "./ConfirmDelete";

import type { GalaxyError, LineItem } from "@up/data";

type Props = {
  errors?: Array<GalaxyError>;
  item: LineItem;
  removeFn: (_id: string) => Promise<unknown>;
};

export function LineItem({ errors, item, removeFn }: Props) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [didError, setDidError] = useState(false);

  const handleRemove = async function () {
    setIsDeleting(true);
    setDidError(false);
    try {
      await removeFn(item._id);
    } catch (err) {
      setDidError(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const removeStep1 = function () {
    setChangeDate(false);
    setConfirmDelete(true);
  };

  return (
    <li
      className={`border border-solid transition-all ${
        confirmDelete
          ? "border-red-500 shadow-red-500 p-5 bg-red-50"
          : changeDate
          ? "border-blue-500 shadow-blue-500 p-5 bg-blue-50"
          : "border-gray-300 px-4 pt-4"
      }`}
      style={{
        boxShadow: confirmDelete
          ? "0 0 0 1px red"
          : changeDate
          ? "0 0 0 1px blue"
          : "",
      }}
    >
      <div className="text-base leading-tight opacity-70">
        {item.formattedStartDate} — {item.formattedEndDate}
      </div>
      <div className="text-lg font-bold leading-tight">
        {item.catalogueItem.name}
      </div>
      <div aria-hidden className="h-3" />
      <div className="flex flex-row justify-between">
        <span className="font-mono text-sm opacity-70">
          {item.formattedRetailPrice}
        </span>
      </div>
      <div className="flex flex-row justify-end">
        {!isDeleting && !confirmDelete && !changeDate ? (
          <button
            className="relative underline leading-none pb-4 pr-4 -right-4 text-sm"
            onClick={() => {
              setChangeDate(true);
            }}
          >
            change dates
          </button>
        ) : null}
        {!isDeleting && !confirmDelete && !changeDate ? (
          <button
            className="relative underline leading-none pb-4 pr-4 -right-4 text-sm"
            onClick={removeStep1}
          >
            remove
          </button>
        ) : null}
      </div>
      {changeDate ? (
        <ChangeDate
          cancel={() => {
            setChangeDate(false);
          }}
          catalogueItemId={item.catalogueItem._id}
          currentCohortId={item._id}
        />
      ) : null}
      {confirmDelete ? (
        <LineItemConfirmDelete
          cancel={() => {
            setConfirmDelete(false);
          }}
          confirm={handleRemove}
          isDeleting={isDeleting}
        />
      ) : null}
    </li>
  );
}
