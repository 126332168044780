import { errorMessages, validate } from "./validation";

import type {
  Dependent,
  Element,
  FormValues,
  RegisterProps,
  Validatable,
} from "./data-types";
import type { UseFormRegister } from "react-hook-form";

export const createValidation = function (
  validationConfig: Validatable,
  formValues: FormValues,
): { validate: Record<string, (userEnteredValue: unknown) => boolean> } {
  const validationObj: Record<string, (userEnteredValue: unknown) => boolean> =
    {};

  for (const [key, validationValue] of Object.entries(validationConfig)) {
    validationObj[key] = function (useEnteredValue: unknown) {
      const func = validate[key as keyof typeof validate];
      return func(useEnteredValue, validationValue, formValues);
    };
  }

  return { validate: validationObj };
};

export const shouldValidate = function (
  element: Element,
  formValues: FormValues,
): boolean {
  const validateAndNoDependents =
    "validate" in element && !("dependent" in element);
  /*
    This covers the case where an Element has validation but is hidden.
    For example: when the user lives in Canada, we should not validate the US State field
  */
  const validateAndDependentCondition =
    "validate" in element &&
    "dependent" in element &&
    dependentElementConditionValueIsMet(element.dependent, formValues);
  return validateAndNoDependents || validateAndDependentCondition;
};

/*
  Invokes register function from react-hook-form with validation options if needed.
  const props = createRegistration(register, textfield, formValues)
  return (
    <input {...props} />
  )
*/
export const createRegistration = function (
  register: UseFormRegister<any>,
  element: Element,
  formValues: FormValues,
): RegisterProps {
  const options = shouldValidate(element, formValues)
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      createValidation(element.validate!, formValues)
    : {};
  return register(element.name as string, options);
};

export const dependentElementConditionValueIsMet = function (
  obj: Dependent | undefined,
  formValues: FormValues,
): boolean {
  if (!obj) {
    return true;
  }
  switch (obj.condition) {
    case "equal":
      return formValues[obj.dependentName] === obj.conditionValue;

    case "notequal":
      return formValues[obj.dependentName] !== obj.conditionValue;

    case "includes":
      if (
        formValues[obj.dependentName] &&
        !Array.isArray(formValues[obj.dependentName])
      ) {
        /*
        !HERE BE DRAGONS!
        You should not be seeing this message
        */
        console.log(`${obj.dependentName} is not an array`);
      }

      return (
        Boolean(formValues[obj.dependentName]) &&
        (formValues[obj.dependentName]?.includes(obj.conditionValue) ?? false)
      );

    default:
      return true;
  }
};

export const getErrorMessage = function (
  type: string,
  validationObj: Validatable,
): string {
  const conditionValue = validationObj[type];
  const key = type as keyof typeof errorMessages;
  return errorMessages[key](conditionValue) || "";
};
