import type { ReactNode } from "react";

type Props = {
  [x: PropertyKey]: any;
  children: ReactNode;
  className?: string;
  hasErrors?: boolean;
  hidden?: boolean;
};

export default function ElementWrapper({
  children,
  className = "",
  hasErrors = false,
  hidden = false,
  ...rest
}: Props): JSX.Element | null {
  if (hidden) {
    return null;
  }

  return (
    <div
      className={`kup-element ${className} ${
        hasErrors ? "kup-element-has-errors" : ""
      }`}
      {...rest}
    >
      {children}
    </div>
  );
}
