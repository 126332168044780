import { motion } from "framer-motion";

import { SUPPORT_EMAIL_DEFAULT, Toast, ToastTitle } from "@up/ui";

type Props = {
  error?: string;
  supportEmail?: string | null;
};

const MOTION = {
  props: {
    animate: {
      opacity: 1,
      rotate: 0,
      y: 0,
    },
    initial: {
      opacity: 0,
      rotate: -9,
      y: 100,
    },
    transition: {
      type: "spring",
      mass: 1,
      stiffness: 150,
    },
  },
};

export default function LoadingError({
  error = "Sorry, we could not load your cart",
  supportEmail = SUPPORT_EMAIL_DEFAULT,
}: Props): JSX.Element {
  return (
    <motion.div {...MOTION.props}>
      <Toast variant="error">
        <ToastTitle>{error}</ToastTitle>
        Please try again later. If this error continues{" "}
        <a className="underline" href={`mailto: ${supportEmail}`}>
          contact us at {supportEmail}
        </a>
        .
      </Toast>
    </motion.div>
  );
}
