import { Question } from "@phosphor-icons/react";
import { useEffect, useRef } from "react";

type Props = {
  cancel: () => void;
  confirm: () => void;
  isDeleting: boolean;
};

export const LineItemConfirmDelete = function ({
  cancel,
  confirm,
  isDeleting,
}: Props) {
  const heading = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (heading.current) {
      heading.current.focus();
    }
  }, []);

  return (
    <div
      className="animation-slide-and-fade-in relative col-span-3 mt-2 flex flex-grow flex-col items-center px-3 pt-5 pb-1 text-center"
      data-testid="checkout-list-item-delete-confirmation"
      data-location="cart-drawer"
    >
      <Question size={56} color="#d00" weight="fill" />
      <div className="mb-6 pt-1 text-2xl" ref={heading} tabIndex={0}>
        Are you sure you want to remove this item?
      </div>
      <div className="flex items-center justify-center">
        <button
          className={`mr-2 rounded border border-solid border-red-700 bg-red-600 px-4 py-1 text-white text-base ${
            isDeleting ? "not-allowed opacity-50" : ""
          }`}
          data-testid="checkout-list-item-remove-yes"
          data-location="cart-drawer"
          disabled={isDeleting}
          onClick={confirm}
        >
          Yes, remove
        </button>
        <button
          className={`rounded border border-solid border-white bg-white px-4 py-1 text-gray-700 text-base ${
            isDeleting ? "not-allowed opacity-50" : ""
          }`}
          data-testid="checkout-list-item-remove-no"
          disabled={isDeleting}
          onClick={cancel}
        >
          No, keep it
        </button>
      </div>
    </div>
  );
};
