export const MODAL_DISMISSED_KEY = "_up-lead-capture-modal-dismissed";
export const SUCCESS_KEY = "_up-lead-capture-success";
export const EXCLUDED_PAGES = [
  "application",
  "apply-now",
  "checkout",
  "orders",
  "scholarship",
  "syllabus",
  "profile",
];

export const MODAL_DELAY = 1000; //7000;
