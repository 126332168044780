import RequiredFlag from "./RequiredFlag";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  html?: string;
  Label?: React.ReactElement;
  required?: boolean;
  text?: string;
};

export default function LabelRadio({
  children,
  className = "",
  html,
  required = false,
  text,
}: Props): JSX.Element {
  return (
    <label
      className={`custom-field custom-field-radio kup-radio-label ${className}`}
    >
      {children}
      <div className="custom-field-label">
        <span className="kup-radio-label-text">
          {html && <span dangerouslySetInnerHTML={{ __html: html }} />}
          {!html && text}
          {required && <RequiredFlag />}
        </span>
        <span className="custom-field-el" />
      </div>
    </label>
  );
}
