"use client";

import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";

import type { ChangeEvent as ReactChangeEvent } from "react";
import type { Control } from "react-hook-form";

type Props = {
  [x: PropertyKey]: any;
  className?: string;
  control: Control;
  name: string;
  validation:
    | { validate: Record<string, (userEnteredValue: unknown) => boolean> }
    | undefined;
};

export default function AutoTextArea(props: Props): JSX.Element {
  const { control, validation, ...restProps } = props;
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [text, setText] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState("auto");

  useEffect(() => {
    if (textAreaRef.current) {
      setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
    }
  }, [text]);

  const onChangeHandler = (event: ReactChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight("auto");
    setText(event.target.value);
  };

  return (
    <Controller
      control={control}
      name={props.name}
      rules={validation ?? {}}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <textarea
            onChange={(e) => {
              onChange(e);
              onChangeHandler(e);
            }}
            style={{
              height: textAreaHeight,
              minHeight: "125px",
            }}
            onBlur={onBlur}
            value={value}
            rows={1}
            ref={(e) => {
              textAreaRef.current = e;
              ref(e);
            }}
            {...restProps}
          />
        );
      }}
    />
  );
}
