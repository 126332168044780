import { show } from "@ebay/nice-modal-react";
import { useAuthStore } from "@kaplan-labs/up-auth-api-client";
import { useEffect } from "react";

import { MODAL_DELAY } from "./constants";
import { shouldDisplayModal } from "./helpers";

export const useModalTimer = function ({ modalID }: { modalID: string }) {
  const auth = useAuthStore();

  useEffect(() => {
    setTimeout(() => {
      if (shouldDisplayModal(modalID)) {
        show(modalID, { modalID });
      }
    }, MODAL_DELAY);
  }, [auth, modalID]);
};
