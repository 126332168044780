import { register } from "@ebay/nice-modal-react";
import { SetupEventTracking } from "@up/analytics";
import { isCartOpen } from "@up/cart";
import { useCart } from "@up/checkout";
import {
  LEAD_CAPTURE_TOPICS_V1_MODAL_ID,
  LeadCaptureTopicsv1Modal,
  useModalTimer,
} from "@up/lead-capture";
import { Navigation } from "@up/navigation";
import {
  AttentionBanner,
  Main,
  NavigationProfileSelector,
  PreviewBanner,
  useOnetrustTrigger,
} from "@up/ui";

import Footer from "./Footer";

import type { ReactNode } from "react";

type Props = {
  attentionBanner?: {
    classes: string;
    text: any;
    theme: string;
  };
  children: ReactNode;
  navigation?: any;
  pageClasses?: string;
  preview?: boolean;
};

register(LEAD_CAPTURE_TOPICS_V1_MODAL_ID, LeadCaptureTopicsv1Modal);

if (typeof window !== "undefined") {
  window.leadCaptureConfig = {
    topics: [
      "Business",
      "Communications",
      "Law and Government",
      "Medicine",
      "STEM",
      "College Credit",
    ],
  };
}

export default function Layout({
  attentionBanner,
  children,
  navigation,
  pageClasses,
  preview,
}: Props) {
  const cart = useCart();
  useOnetrustTrigger();
  useModalTimer({
    modalID: LEAD_CAPTURE_TOPICS_V1_MODAL_ID,
  });

  return (
    <SetupEventTracking
      className={`flex min-h-dvh flex-col ${pageClasses || ""}`}
    >
      {preview ? <PreviewBanner /> : null}

      <AttentionBanner
        className={`py-3 theme-white ${attentionBanner?.classes ?? ""}`}
        field={attentionBanner?.text}
      />

      <Navigation
        cartVariant="button-red"
        items={navigation?.data?.links || []}
        logo="/logo-header.png"
        logoAlt="Georgetown University Logo"
        profileVariant="button-white"
        signInVariant="button-link"
      />

      <NavigationProfileSelector
        cartCount={cart?.lineItems?.length}
        openCart={() => {
          isCartOpen.set(true);
        }}
      />

      <Main>{children}</Main>

      <Footer />
    </SetupEventTracking>
  );
}
