import type { InventoryItem } from "./types";

export const FILTER_NAMES = {
  START_DATE: "season",
  DURATION: "duration",
  COURSENAME: "course",
} as const;

export type FiltersEnabledType = {
  startDate: boolean;
  duration: boolean;
  course: boolean;
};

export type FilterType = {
  name: string;
  type: string;
  enabled: boolean;
  options: Array<{
    displayName: string;
    value: string;
    selected: boolean;
    matches: Array<string>;
  }>;
};

const JAN_MAY = "January - May";
const JUN_AUG = "June - August";
const SEP_DEC = "September - December";

/*
  Durations come in as a string: "2 weeks". When using default array#sort
  for "12 weeks", "8 weeks", "12 weeks" is put in the front
*/
const _sortDuration = function (_a: string, _b: string) {
  const a = parseInt(_a.replace(/^\D+/g, ""));
  const b = parseInt(_b.replace(/^\D+/g, ""));

  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

export function generateStartDateFilter({
  enabledFilters,
  inventoryItems,
  previousFilter,
}: {
  enabledFilters: FiltersEnabledType;
  inventoryItems: Array<InventoryItem>;
  previousFilter?: FilterType;
}): FilterType {
  const seasons = [
    ...new Set(
      inventoryItems.map((c) => {
        const month = new Date(c.startDate).getMonth();
        if (month >= 0 && month <= 4) {
          return JAN_MAY;
        }
        if (month >= 5 && month <= 7) {
          return JUN_AUG;
        } else {
          return SEP_DEC;
        }
      }),
    ),
  ].sort();

  const isSelected = (displayName: string, previousFilter?: FilterType) => {
    if (!previousFilter) {
      return false;
    }
    const match = previousFilter.options.find(
      (f) => f.displayName === displayName,
    );
    return match ? match.selected : false;
  };

  const options = seasons.map((season) => {
    if (season === JAN_MAY) {
      return {
        value: JAN_MAY,
        displayName: JAN_MAY,
        selected: isSelected(JAN_MAY, previousFilter),
        matches: ["January", "February", "March", "April", "May"],
      };
    }
    if (season === JUN_AUG) {
      return {
        value: JUN_AUG,
        displayName: JUN_AUG,
        selected: isSelected(JUN_AUG, previousFilter),
        matches: ["June", "July", "August"],
      };
    }

    return {
      value: SEP_DEC,
      displayName: SEP_DEC,
      selected: isSelected(SEP_DEC, previousFilter),
      matches: ["September", "October", "November", "December"],
    };
  });

  return {
    name: FILTER_NAMES.START_DATE,
    type: "radio",
    enabled: enabledFilters.startDate && options.length > 1,
    options,
  };
}

export function generateDurationFilter({
  enabledFilters,
  inventoryItems,
  previousFilter,
}: {
  enabledFilters: FiltersEnabledType;
  inventoryItems: Array<InventoryItem>;
  previousFilter?: FilterType;
}): FilterType {
  const enabled =
    enabledFilters.duration &&
    [...new Set(inventoryItems.map((c) => c.cohortLength))].length > 1;

  const isSelected = (displayName: string, previousFilter?: FilterType) => {
    if (!previousFilter) {
      return false;
    }
    const match = previousFilter.options.find(
      (f) => f.displayName === displayName,
    );
    return match ? match.selected : false;
  };

  return {
    name: FILTER_NAMES.DURATION,
    type: "radio",
    enabled,
    options: [...new Set(inventoryItems.map((c) => c.cohortLength))]
      .sort(_sortDuration)
      .map((c) => ({
        displayName: c,
        value: c,
        selected: isSelected(c, previousFilter),
        matches: [c],
      })),
  };
}

export function generateCourseFilter({
  enabledFilters,
  inventoryItems,
}: {
  enabledFilters: FiltersEnabledType;
  inventoryItems: Array<InventoryItem>;
}): FilterType {
  const enabled =
    enabledFilters.course &&
    [
      ...new Set(
        inventoryItems.map((c) => {
          return c.catalogueItem.name;
        }),
      ),
    ].length > 1;

  const getNameFromID = function (id: string) {
    const item = inventoryItems.find((c) => c.catalogueItem._id === id);
    if (!item) {
      console.log(`Could not find course with ID ${id}`);
      return id;
    }
    return item.catalogueItem.name;
  };

  return {
    name: FILTER_NAMES.COURSENAME,
    type: "select",
    enabled,
    options: [...new Set(inventoryItems.map((c) => c.catalogueItem._id))]
      .sort()
      .map((itemID) => ({
        value: itemID,
        displayName: getNameFromID(itemID),
        selected: false,
        matches: [itemID],
      })),
  };
}

export function resetCohortFilters(filters: Array<FilterType>) {
  return filters.map(function (filter) {
    return {
      ...filter,
      options: filter.options.map(function (option) {
        return {
          ...option,
          selected: false,
        };
      }),
    };
  });
}

export function handleCohortFilterChange(
  filters: Array<FilterType>,
  filterName: string,
  optionName: string,
) {
  return filters.map(function (filter) {
    if (filter.name !== filterName) {
      return filter;
    }

    return {
      ...filter,
      options: filter.options.map(function (option) {
        if (option.value === optionName) {
          return {
            ...option,
            selected: !option.selected,
          };
        }

        return option;
      }),
    };
  });
}

export function cohortPassesFilters(
  cohort: InventoryItem,
  filters: Array<FilterType>,
) {
  const doTheFilter = function (value: string, filter: FilterType) {
    const selectedFilters = filter.options.filter((f) => f.selected);
    if (!selectedFilters.length) {
      return true;
    }

    return selectedFilters.some((f) => {
      return f.matches.find((match) => value.match(match));
    });
  };

  const startDateFilter = filters.find(
    (f) => f.name === FILTER_NAMES.START_DATE,
  ) as FilterType;
  const durationFilter = filters.find(
    (f) => f.name === FILTER_NAMES.DURATION,
  ) as FilterType;
  const courseFilter = filters.find(
    (f) => f.name === FILTER_NAMES.COURSENAME,
  ) as FilterType;

  const startMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(new Date(cohort.startDate));

  const startDateMatch = startDateFilter
    ? doTheFilter(startMonth, startDateFilter)
    : true;
  const durationMatch = durationFilter
    ? doTheFilter(cohort.cohortLength, durationFilter)
    : true;
  const courseMatch = courseFilter
    ? doTheFilter(cohort.catalogueItem._id, courseFilter)
    : true;

  return startDateMatch && durationMatch && courseMatch;
}

export function filterCohorts(
  cohorts: Array<InventoryItem>,
  filters: Array<FilterType>,
) {
  if (!filters) {
    return cohorts;
  }

  return cohorts.filter(function (cohort) {
    return cohortPassesFilters(cohort, filters);
  });
}
