import SelectFromArray from "./SelectFromArray";
import SelectFromObject from "./SelectFromObject";

import type { ArrayProps } from "./SelectFromArray";
import type { ObjectProps } from "./SelectFromObject";
import type { RegisterProps } from "../data-types";

type Props = {
  [x: PropertyKey]: any;
  className?: string;
  data: any;
  displayArrow?: boolean;
  displayKey?: string;
  hasErrors?: boolean;
  name: string;
  placeholderText?: string;
  selectedValue?: string;
  valueKey?: string;
  registerProps: RegisterProps;
};

export type Ref = HTMLSelectElement;

export default function Select(props: Props): JSX.Element {
  return props.displayKey ? (
    <SelectFromObject {...(props as ObjectProps)} />
  ) : (
    <SelectFromArray {...(props as ArrayProps)} />
  );
}
