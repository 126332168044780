import { useRef } from "react";
import { useEventListener } from "usehooks-ts";

import { analyticsEvent } from "./functions";

import type { AnalyticsData } from "./types";
import type { ReactNode } from "react";

export function SetupEventTracking({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const wrapper = useRef<HTMLDivElement | null>(null);

  const handleEvent = function (_e: Event) {
    const event = _e as MouseEvent;
    const target = event.target as HTMLElement;

    const classes: Array<string> = target
      ? Array.from(target.classList.values())
      : [];
    const eventClass = classes.find((c: string) => c.match(/^event-/));
    if (!eventClass) {
      return;
    }

    const payload: AnalyticsData = {
      event: eventClass.replace(/^event-/, ""),
    };

    const component = target
      ? target.closest("[data-analytics-component]")
      : null;
    if (component) {
      payload.component = component.getAttribute("data-analytics-component");
    }

    analyticsEvent(payload);
  };

  useEventListener("click", handleEvent, wrapper);

  return (
    <div className={className} ref={wrapper}>
      {children}
    </div>
  );
}
