import { clsx } from "clsx";
import { AnimatePresence } from "framer-motion";

import { FILTER_NAMES, FilterType } from "@up/data";

import { GRID_CLASSES } from "./grid";

const STYLED_RADIO = clsx(
  "flex items-center justify-between",
  "bg-white outline-gray-400 outline outline-1 outline-offset-0 rounded py-0.5 px-3",
  "has-[:checked]:outline-[--accent-primary]",
);

const STYLED_RADIO_CHECK = clsx(
  "flex items-center justify-center h-[20px] w-[20px] border border-gray-300 rounded-sm",
  "peer-checked:border-[--accent-primary] peer-checked:bg-[--accent-primary]",
);

function _filterStyles(name: string) {
  switch (name) {
    case FILTER_NAMES.START_DATE:
      return "grid-cols-1 @md:grid-cols-2 @lg:grid-cols-3";
    case FILTER_NAMES.DURATION:
      return "grid-cols-2 @md:grid-cols-3";
    default:
      return "";
  }
}

export const Filters = function Filters({
  filters,
  handleFilterToggle,
}: {
  filters: Array<FilterType>;
  handleFilterToggle: (filterName: string, optionName: string) => void;
}) {
  return (
    <AnimatePresence>
      {filters
        .filter((f) => f.enabled)
        .map(function (filter) {
          return (
            <div key={filter.name} className={`@container ${GRID_CLASSES}`}>
              <fieldset className="col-start-2 col-span-1">
                <legend>Choose a {filter.name}</legend>
                <div className={`grid gap-2 ${_filterStyles(filter.name)}`}>
                  {filter.type === "radio" &&
                    filter.options.map(function (option, index) {
                      return (
                        <label
                          key={index}
                          className={STYLED_RADIO}
                          data-element="cohort-list-filter-option"
                        >
                          <span>{option.displayName}</span>
                          <input
                            checked={option.selected}
                            name={`${filter.name.replace(
                              " ",
                              "",
                            )}-${option.value.replace(/ /g, "")}`}
                            onClick={() => {
                              handleFilterToggle(filter.name, option.value);
                            }}
                            onChange={() => {}}
                            type="checkbox"
                            value={option.value}
                            className="peer sr-only"
                          />
                          <div aria-hidden className={STYLED_RADIO_CHECK}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="11"
                              fill="none"
                              viewBox="0 0 15 11"
                            >
                              <path
                                fill="#fff"
                                d="m14.165 1.917-8.307 8.307a.78.78 0 0 1-1.104 0L1.12 6.59a.78.78 0 0 1 1.103-1.104L5.307 8.57 13.063.814a.78.78 0 0 1 1.104 1.104z"
                              ></path>
                            </svg>
                          </div>
                        </label>
                      );
                    })}
                </div>

                {filter.type === "select" && (
                  <select
                    name={filter.name.replace(" ", "")}
                    onChange={(e) => {
                      handleFilterToggle(filter.name, e.target.value);
                    }}
                    style={{ maxWidth: "100%" }}
                  >
                    <option value="">All Courses</option>
                    {filter.options.map(function (option, index) {
                      return (
                        <option key={index} value={option.value}>
                          {option.displayName}
                        </option>
                      );
                    })}
                  </select>
                )}
                <div aria-hidden className="h-4" />
              </fieldset>
            </div>
          );
        })}
    </AnimatePresence>
  );
};
