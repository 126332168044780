"use client";

import { HandPalm, Info, Warning } from "@phosphor-icons/react";
import cn from "clsx";
import { createContext, useContext } from "react";

import { SparkleIcon } from "./sparkle";
import { useAutoFocus } from "../use-autofocus";
import { WaveCheck } from "../wave-check";

import type { ReactNode } from "react";

type Variant = "default" | "error" | "info" | "success" | "warning" | "feature";
type Size = "default" | "large";

type BaseProps = {
  showIcon?: boolean;
  sticky?: boolean;
  size?: Size;
  variant?: Variant;
};

type ToastContextType = {
  showIcon: boolean;
  sticky: boolean;
  size: Size;
  variant: Variant;
};

export type ToastProps =
  | (BaseProps & {
      children?: ReactNode;
      text: string;
    })
  | (BaseProps & {
      children: ReactNode;
      text?: string;
    });

const ICON_SIZE = 44;

const ToastContext = createContext<ToastContextType>({
  showIcon: true,
  sticky: false,
  variant: "default",
  size: "default",
});

const getIcon = function (variant: Variant) {
  switch (variant) {
    case "error":
      return (
        <Warning
          className="block w-full"
          color="#F2836A"
          size={ICON_SIZE}
          weight="fill"
        />
      );
    case "success":
      return (
        <WaveCheck className="block w-full text-[#8DC630]" size={ICON_SIZE} />
      );
    case "warning":
      return (
        <HandPalm className="block w-full text-[#EC9240]" size={ICON_SIZE} />
      );
    case "info":
      return <Info className="block w-full text-[#5EB2D7]" size={ICON_SIZE} />;
    case "feature":
      return (
        <SparkleIcon className="block w-full text-[#5EB2D7]" size={ICON_SIZE} />
      );
    default:
      return <div aria-hidden />;
  }
};

function toastClasses(variant: Variant) {
  switch (variant) {
    case "warning":
      return "bg-[#FFF2C9] text-[#664E24]";
    case "success":
      return "bg-[#E1FFC9] text-[#416624]";
    case "info":
    case "feature":
      return "bg-[#C9F5FF] text-[#214F5E]";
    case "error":
      return "bg-[#F8D8D6] text-[#663424]";
    case "default":
    default:
      return "bg-[#e7e7e7] text-gray-600";
  }
}

function boxShadowColor(variant: Variant) {
  switch (variant) {
    case "default":
    default:
      return "#bbb";
    case "warning":
      return "#ECD899";
    case "success":
      return "#A4E272";
    case "info":
    case "feature":
      return "#98E6F8";
    case "error":
      return "#F2A9A5";
  }
}

export function Toast({
  children,
  showIcon = true,
  sticky = false,
  size = "default",
  text,
  variant = "default",
}: ToastProps): JSX.Element {
  return (
    <ToastContext.Provider
      value={{
        showIcon,
        sticky,
        size,
        variant,
      }}
    >
      <div className="@container">
        <div
          className={`kup-toast w-full z-50 text-sm @md:text-base kup-toast-${variant} ${
            sticky ? "sticky top-2" : ""
          } animation-fade-in flex items-center rounded-2xl ${
            showIcon ? "px-4" : "px-6"
          } py-3 ${toastClasses(variant)}`}
          role="alert"
          style={{
            boxShadow: `1px 1px 0 ${boxShadowColor(variant)}`,
          }}
          data-testid="toast"
          data-element="toast"
        >
          {showIcon && variant !== "default" && (
            <div
              className="kup-toast-icon mr-3 flex-shrink-0 w-[24px] @md:w-[44px]"
              data-testid="toast-icon"
            >
              {getIcon(variant)}
            </div>
          )}
          <div
            className={`kup-toast-message leading-tight @md:leading-normal ${
              size === "large"
                ? "text-base @md:text-lg"
                : "text-sm @md:text-base"
            }`}
            data-testid="toast-message"
          >
            {children ? children : null}
            {!children ? text : null}
          </div>
        </div>
      </div>
    </ToastContext.Provider>
  );
}

function toastTitleClasses(variant: Variant) {
  switch (variant) {
    case "info":
    case "feature":
      return "text-[#092731]";
    case "warning":
      return "text-[#311C09]";
    case "success":
      return "text-[#1D3109]";
    case "error":
      return "text-[#311009]";
    case "default":
    default:
      return "text-gray-800";
  }
}

export function ToastTitle({
  autoFocus = false,
  children,
  inline = false,
  size,
  variant,
  ...rest
}: {
  autoFocus?: boolean;
  children: ReactNode;
  inline?: boolean;
  size?: "default" | "large";
  variant?: Variant;
}) {
  const context = useContext(ToastContext);
  const text = useAutoFocus();

  return (
    <span
      className={cn(
        "font-bold",
        "leading-tight",
        "focus:outline-none focus-visible:outline-none",
        inline ? "mr-1" : "block",
        (size ?? context.size) === "large"
          ? "text-lg @md:text-xl"
          : "text-base @md:text-lg",
        toastTitleClasses(variant ?? context.variant),
      )}
      data-testid="toast-title"
      ref={autoFocus ? text : null}
      tabIndex={-1}
      {...rest}
    >
      {children}
    </span>
  );
}
