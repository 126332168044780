import type { ReactNode } from "react";

export const Main = function Main({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <main id="main-content" className={`relative z-10 grow ${className}`}>
      {children}
    </main>
  );
};
