"use client";

import { useIsClient } from "usehooks-ts";

import type { ReactNode } from "react";

export const BrowserOnly = function BrowserOnly({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const isClient = useIsClient();

  return (
    <div className={`${className ?? ""}`} suppressHydrationWarning={true}>
      {isClient ? children : null}
    </div>
  );
};
