import type { CSSProperties } from "react";

type Props = {
  className?: string;
  color?: string;
  size?: string;
};

export function Spinner({
  color = "#555",
  className = "",
  size = "24px",
}: Props): JSX.Element {
  const style = { "--spinner-color": color, fontSize: size } as CSSProperties;

  return (
    <div
      aria-hidden="true"
      className={`spinner relative inline-block h-[1em] w-[1em] ${className}`}
      data-testid="spinner"
      style={style}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
