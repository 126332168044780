type Props = {
  className?: string;
  text: string | null;
};

export default function ErrorMessage({
  className = "",
  text,
  ...rest
}: Props): JSX.Element | null {
  if (text) {
    return (
      <p className="kup-error-message inline-block" {...rest}>
        {text}
      </p>
    );
  } else {
    return null;
  }
}
