import { asLink } from "@prismicio/client";

import type { Auth } from "@kaplan-labs/up-auth-api-client";
import type { BooleanField, KeyTextField, LinkField } from "@prismicio/client";

export interface PrismicNavigationItem {
  text: KeyTextField;
  link: LinkField;
  type: string;
  level: "1" | "2" | "filled";
  is_header: BooleanField;
  css_classes: KeyTextField;
  authenticated_link: BooleanField;
}

export interface PrismicNavigationItemDecorated {
  properties: PrismicNavigationItem;
  children?: PrismicNavigationItem[];
}

export const getButtonItems = function getButtonItems(
  items: PrismicNavigationItem[] = [],
): PrismicNavigationItem[] {
  return items.filter((i) => Boolean(i.type.match(/button/)));
};

/*
  Navigation items coming from Prismic are in a flat list:

  {
    text: 'FAQ',
    level: '1',
    link: ...,
  },
  {
    text: 'Courses',
    level: '1',
  },
  {
    text: 'How to Code',
    level: '2',
    link: ...,
  }

  This function returns a shape for the UI

  {
    properties: {
      text: 'FAQ',
      level: '1',
      link: ...,
    },
  },
  {
    properties: {
      text: 'Courses',
      level: '1'
    },
    children: [
      {
        text: 'How to Code',
        level: '2',
        link: ...,
      }
    ]
  },

*/
export const getTextItems = function getTextItems(
  items: PrismicNavigationItem[],
): PrismicNavigationItemDecorated[] {
  const dirtyList = (items || [])
    .filter((i) => Boolean(i.type.match(/text/)))
    .map(function (item, index) {
      if (item.level.includes("2")) {
        return null;
      }

      const decoratedItem: PrismicNavigationItemDecorated = {
        properties: item,
      };

      const nextItem = items[index + 1];
      /*
        If the next item in the list is level 2 (nested),
        keep looping until '1' is found to generate array of children.
        See comment above about Navigation Items being a flat list.
      */
      if (nextItem?.level.includes("2")) {
        const children: Array<PrismicNavigationItem> = [];
        let counter = 1;
        let loop = true;

        while (loop) {
          const sibling = items[index + counter];
          if (sibling && sibling.level.includes("2")) {
            children.push(sibling);
          } else {
            loop = false;
          }
          counter += 1;
        }

        decoratedItem.children = children;
      }

      return decoratedItem;
    });

  return dirtyList.filter(Boolean) as PrismicNavigationItemDecorated[];
};

export const filterByAuth = function filterByAuth(
  auth: Auth | undefined | null,
  isAuthenticatedLink: boolean,
) {
  if ((!auth || !auth.currentUser) && isAuthenticatedLink) {
    return false;
  }

  if (auth?.currentUser && isAuthenticatedLink) {
    return true;
  }

  if (auth?.currentUser && !isAuthenticatedLink) {
    return false;
  }

  return true;
};

export const isInternalLink = function (prismicLink?: LinkField) {
  if (!prismicLink) return false;

  const href = asLink(prismicLink, {
    linkResolver(doc) {
      return doc ? `/${doc.uid}` : "/";
    },
  });

  return href?.match(/^\//);
};

export const linkType = function (
  prismicLink?: LinkField,
  hasChildren?: boolean,
): "internal" | "external" | "parent" | "none" {
  if (!prismicLink) return "none";
  if (hasChildren) return "parent";
  return isInternalLink(prismicLink) ? "internal" : "external";
};
