import type { RegisterProps } from "../data-types";

type Props = {
  [x: PropertyKey]: any;
  className?: string;
  hasErrors?: boolean;
  type?: string;
  registerProps: RegisterProps;
};

export default function InputText({
  className = "",
  hasErrors = false,
  type = "text",
  registerProps,
  ...rest
}: Props): JSX.Element {
  return (
    <input
      aria-invalid={hasErrors}
      className={`kup-input-text ${className}`}
      id={registerProps["name"]}
      type={type}
      {...registerProps}
      {...rest}
    />
  );
}
