import { useTheme } from "@up/data";
import { Toast, ToastTitle } from "@up/ui";

export default function GenericError() {
  const theme = useTheme();

  return (
    <Toast variant="error">
      <ToastTitle>Error. Please try again later.</ToastTitle>
      If you continue seeing issues please{" "}
      <a className="underline" href={`mailto:${theme.supportEmail}`}>
        email support
      </a>
      .
    </Toast>
  );
}
