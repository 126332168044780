export function SparkleIcon({ size, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="#72AED2"
      viewBox="0 0 256 256"
      {...rest}
    >
      <path fill="none" d="M0 0H256V256H0z"></path>
      <path
        fill="none"
        stroke="#72AED2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        d="M138.7 175.5l-19.2 52.1a8 8 0 01-15 0l-19.2-52.1a8.1 8.1 0 00-4.8-4.8l-52.1-19.2a8 8 0 010-15l52.1-19.2a8.1 8.1 0 004.8-4.8l19.2-52.1a8 8 0 0115 0l19.2 52.1a8.1 8.1 0 004.8 4.8l52.1 19.2a8 8 0 010 15l-52.1 19.2a8.1 8.1 0 00-4.8 4.8z"
        className="star-3"
      ></path>
      <path
        fill="none"
        stroke="#72AED2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
        d="M176 16L176 64"
        className="star-1"
      ></path>
      <path
        fill="none"
        stroke="#72AED2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
        d="M200 40L152 40"
        className="star-1"
      ></path>
      <path
        fill="none"
        stroke="#72AED2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M224 72L224 104"
        className="star-2"
      ></path>
      <path
        fill="none"
        stroke="#72AED2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M240 88L208 88"
        className="star-2"
      ></path>
    </svg>
  );
}
